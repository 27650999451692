<template>
  <b-card class="p-2">
    <!-- form -->
    <validation-observer ref="addsidedishesForm">
      <b-form @submit.prevent="submitForm">
        <h3 class="mb-2">Edit sidedish</h3>
        <b-row>
          <!-- first name -->

          <b-col cols="6">
            <b-form-group label="Name" label-for="name">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="name"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="Price" label-for="price">
              <validation-provider
                #default="{ errors }"
                name="Price"
                rules="required"
              >
                <b-form-input id="price" v-model="price" placeholder="Price" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12" class="text-right">
            <b-button type="submit" requi variant="primary" class="mr-1 mt-1">
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BCard,
  BListGroup,
  BListGroupItem,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import store from "@/store/index";
import { ref, onUnmounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import usesidedishList from "./usesidedishList";
import { required, integer } from "@validations";
import sidedishStoreModule from "../sidedishStoreModule";
import axios from '@axios';
export default {
  components: {
    BCard,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    required,
    integer,
  },
  data() {
    return {
      price: null,
      name: null,
      id: null,
    };
  },
  async mounted() {
    this.id = this.$route.params.id;
    const promises = [
      axios.get(`admin/getone/sidedish/${this.id}`),
    ];
    const allResponses = await Promise.all(promises);
    this.price = allResponses[0].data.data.price;
    this.name = allResponses[0].data.data.name;
  },
  methods: {
    submitForm() {
      this.$refs.addsidedishesForm.validate().then(async (success) => {
        if (success) {
          let formData = {
            name: this.name,
            price: this.price,
            id: this.id,
          };
          var result = await this.updatesidedishes(formData);
          console.log(result);
          setTimeout(() => {
          history.back();
          }, 1000);
        }
      });
    },
  },
  setup() {
    // Register module
    const APP_STORE_MODULE_NAME = "master-sidedishes";

    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, sidedishStoreModule);

    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    const { fetchsidedishes,updatesidedishes } = usesidedishList();

    return {
      fetchsidedishes,updatesidedishes
    };
  },
};
</script>
